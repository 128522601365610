import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from "react-query";
import api from "src/services/api";
import { ObjectToQueryString } from "src/utils/uri";

// API methods - not exposed outside this class
// getPartners = Get list of all partners
const getPartners = async (options) => {
  const { data } = await api.get(`/partner?${ObjectToQueryString(options)}`);
  return data;
};

const getPartner = async (partnerId) => {
  const { data } = await api.get(`/partner/${partnerId}`);
  return data;
};

// getPartnerUsers = Get list of all users under a partner
const getPartnerUsers = async (partnerId, options) => {
  const { data } = await api.get(
    `/partner/${partnerId}/user?${ObjectToQueryString(options)}`
  );
  return data;
};

const getPartnerInviteList = async (partnerId) => {
  const { data } = await api.get(`/partner/${partnerId}/invite`);
  return data;
};

const addPartner = async (partner) => {
  const { data } = await api.post(`/partner`, partner);
  return data;
};

const addPartnerUserRole = async ({ partnerId, addRoleModel }) => {
  const { data } = await api.post(`/partner/${partnerId}/user`, addRoleModel);
  return data;
};

const deletePartnerUserRole = async ({ partnerId, userId, roleId }) => {
  const { data } = await api.delete(
    `/partner/${partnerId}/user/${userId}/role/${roleId}`
  );
  return data;
};

const invitePartnerUser = async ({ partnerId, invite }) => {
  const { data } = await api.post(`/partner/${partnerId}/invite`, invite);
  return data;
};

const resendPartnerUserInvite = async ({ partnerId, inviteId }) => {
  const { data } = await api.post(
    `/partner/${partnerId}/invite/${inviteId}/resend`
  );
  return data;
};

const deletePartnerUserInvite = async ({ partnerId, inviteId }) => {
  const { data } = await api.delete(`/partner/${partnerId}/invite/${inviteId}`);
  return data;
};

const disablePartnerUser = async ({ partnerId, userId }) => {
  const { data } = await api.post(
    `/partner/${partnerId}/user/${userId}/disable`
  );
  return data;
};

// Custom hooks
export const usePartner = (partnerId) => {
  return useQuery(["partner", { partnerId }], () => getPartner(partnerId), {
    enabled: !!partnerId,
  });
};

//We actually don't use a partner list anywhere. We use "userPartner" list, which is
//partner list filtered down to just partners that the user has access to (if user is
// an admin, then this would be all partners). So should we kill this? Should we start
// referring to the userPartner query/list as partner query/list? etc...

export const usePartnerList = (page = 0, perPage = 10) => {
  return useQuery(["partnerList", { page, perPage }], () =>
    getPartners({ page, perPage })
  );
}; //TODO: Remove once infinitepartnerlist is hooked up

// export const useInfinitePartnerList = (filters) => {
//   return useInfiniteQuery({
//     queryKey: ["partnerList", filters],
//     queryFn: ({ pageParam = 0 }) =>
//       getPartners({ page: pageParam, ...filters }),
//     getNextPageParam: (lastPage, pages) => {
//       return lastPage.pageNumber + 1 < lastPage.totalPages
//         ? lastPage.pageNumber + 1
//         : undefined;
//     },
//   });
// };

export const useAddPartnerUserRole = () => {
  const queryClient = useQueryClient();

  return useMutation(addPartnerUserRole, {
    onSuccess: (data, { partnerId }) => {
      return queryClient.invalidateQueries([
        "partnerUserList",
        { partnerId: partnerId },
      ]);
    },
  });
};

export const useRemovePartnerUserRole = () => {
  const queryClient = useQueryClient();

  return useMutation(deletePartnerUserRole, {
    onSuccess: (data, { partnerId }) => {
      return queryClient.invalidateQueries([
        "partnerUserList",
        { partnerId: partnerId },
      ]);
    },
  });
};

export const useInfinitePartnerUserList = (partnerId, filters) => {
  return useInfiniteQuery({
    queryKey: ["partnerUserList", partnerId, filters],
    queryFn: ({ pageParam = 0 }) => {
      return getPartnerUsers(partnerId, { page: pageParam, ...filters });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.pageNumber + 1 < lastPage.totalPages
        ? lastPage.pageNumber + 1
        : undefined;
    },
  });
};

export const useAddPartner = () => {
  const queryClient = useQueryClient();

  return useMutation(addPartner, {
    onSuccess: (data) => {
      return queryClient.invalidateQueries(["partnerList"]);
    },
  });
};

export const usePartnerInviteList = (partnerId) => {
  return useQuery(["partnerInviteList", { partnerId }], () =>
    getPartnerInviteList(partnerId)
  );
};

export const useInvitePartnerUser = () => {
  const queryClient = useQueryClient();

  return useMutation(invitePartnerUser, {
    onSuccess: (data, { partnerId }) =>
      queryClient.invalidateQueries([
        "partnerInviteList",
        { partnerId: partnerId },
      ]),
  });
};

export const useResendPartnerUserInvite = () => {
  const queryClient = useQueryClient();

  return useMutation(resendPartnerUserInvite, {
    onSuccess: (data, { partnerId }) =>
      queryClient.invalidateQueries([
        "partnerInviteList",
        { partnerId: partnerId },
      ]),
  });
};

export const useDeletePartnerUserInvite = () => {
  const queryClient = useQueryClient();

  return useMutation(deletePartnerUserInvite, {
    onSuccess: (data, { partnerId }) =>
      queryClient.invalidateQueries([
        "partnerInviteList",
        { partnerId: partnerId },
      ]),
  });
};

export const useDisablePartnerUser = () => {
  const queryClient = useQueryClient();

  return useMutation(disablePartnerUser, {
    onSuccess: (data, { partnerId }) => {
      return queryClient.invalidateQueries([
        "partnerUserList",
        { partnerId: partnerId },
      ]);
    },
  });
};
